const CryptoJs = require("crypto-js");

import { Popover } from 'flowbite';
/* --- Load Style --- */
import '../scss/main.scss';
/* --- Dev Mode --- */

if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
}

const urlSite = window.location.href;
const newUrl = new URL(urlSite);
const searchParams = newUrl.searchParams;
const nameStorage = 'data_form_verify';
const nameStorageEnable = 'data_form_enable';
const ketH = '5c7997faf6c60f5bac0d4ceb8d207d6997a2d4b2fb9374b350695870eb179b73';

const inputId = document.querySelector('[data-attribute="user-id"]');
const spanName = document.getElementById('name');
const cardName = document.getElementById('card-name');
const msgError = document.getElementById('message-error');
const msgDisable = document.getElementById('message-disable');
const btnSubmit = document.getElementById('btn-submit');

let userId = searchParams.get('userid');
let nameId = searchParams.get('name');
let userIdEncrypt = null;

if (userId) {
  inputId.value = userId;
  userIdEncrypt = CryptoJs.AES.encrypt(userId, ketH).toString();
} else {
  msgError.classList.add('block');
  msgError.classList.remove('hidden');
  btnSubmit.disabled = true;
}

if (nameId) {
  spanName.textContent = nameId;
} else {
  cardName.classList.add('hidden');
}

const allInputsCheckbox = document.querySelectorAll('input[type="checkbox"]');
const allInputsText = document.querySelectorAll('input[type="text"]');
const allTextarea = document.querySelectorAll('textarea[type="text"]');
const allSelect = document.querySelectorAll('select');
const localDatas = window.localStorage.getItem(nameStorage);
const localDatasJson = JSON.parse(localDatas);
const data = JSON.parse(localStorage.getItem(nameStorage));
const formEnable = JSON.parse(localStorage.getItem(nameStorageEnable));




/*****
 * Function Decrypt
 */

const decryptId = id => {
  const idEncrypt = CryptoJs.AES.decrypt(id, ketH);
  const idDecrypt = idEncrypt.toString(CryptoJs.enc.Utf8);
  return idDecrypt;
}

/*****
 * Button submit
 */

btnSubmit.addEventListener('click', e => {

  const removeData = (data.find(o => decryptId(o.userId) !== userId)) ? data.filter(o => decryptId(o.userId) !== userId) : null ;
  let removeId = [];

  (removeData === null) ? window.localStorage.removeItem(nameStorage) :  window.localStorage.setItem(nameStorage, JSON.stringify(removeData));

  removeId = (typeof formEnable !== 'undefined' && formEnable !== null) ? formEnable.concat(userIdEncrypt) : [userIdEncrypt] ;

  window.localStorage.setItem(nameStorageEnable, JSON.stringify(removeId));
})

/*****
 * Enable/Disable Form
 */


if (typeof formEnable !== 'undefined' && formEnable !== null){
  if(formEnable.find(i => decryptId(i) === userId)) {
    msgDisable.classList.add('block');
    msgDisable.classList.remove('hidden');
    btnSubmit.disabled = true;
 }
}

/*****
 * New vs Old User
 */

let dataAll =  [];


if( data !== null){


  if (data.find(o => decryptId(o.userId) === userId)) {
    const dataAllI = data.find(i => decryptId(i.userId) === userId);
    dataAll = dataAllI.datas;

  } else {
    dataAll = [];
  }
} else {
  dataAll = [];
}


/*****
 * Check Form
 */


const checkValueStorage = (dataOrigin, storageDatas, item, type) => {


  /*****
   * DataOrigin = Datas ou array vide
   */

  if (dataOrigin.length !== 0) {

    for (const formId of data) {

      if (decryptId(formId.userId) === userId) {
        const itemType = formId.datas.find(x => x.id === item.id);

        if (itemType !== undefined && itemType.value) {
          const selectorId = document.getElementById(item.id);

          if (type === 'checkbox') {
            selectorId.checked = true;
          }

          if (type === 'text') {
            // Decrypt
            const bytes = CryptoJs.AES.decrypt(itemType.value, ketH);
            const originalText = bytes.toString(CryptoJs.enc.Utf8);

            selectorId.value = originalText;
          }

          if (type === 'select') {
            selectorId.value = itemType.value;
          }
        }
      }
    }
  }
}

const storeValueStorage = (dataOrigin, nameStorage, item, type) => {

  /*****
   * dataOrigin = Datas ou array vide => dataAll
   * nameStorage = name
   * item = id item
   * type= text, checkbox, select
   *
   */

  const itemType = (type === 'text') ? 'input' : 'change';
  const newData = [];

  item.addEventListener(itemType, e => {
    const itemId = e.target.id;
    let itemValue = e.target.value;

    if (type === 'text') {
      itemValue = CryptoJs.AES.encrypt(itemValue, ketH).toString();
    }

    if (type === 'checkbox') {
      itemValue = (e.target.checked) ? true : false;
    }

    const arrayItem = {
      id: itemId,
      value: itemValue,
    };


    if (dataOrigin.length !== 0) {
      if (dataOrigin.find(i => i.id === arrayItem.id)) {
        dataOrigin.map((item, i) => {
          if (item.id === itemId) {
            dataAll[i] = {id: itemId, value: itemValue}
          }
        })
      } else {
        dataAll.push(arrayItem);
      }

    } else {
      dataAll.push(arrayItem);
    }


    let dataObj = {userId: userIdEncrypt, datas: dataAll};
    let dataArray = [];

    if (data === null){
      dataArray = [dataObj];

    } else if (dataOrigin !== null && data.find(o => decryptId(o.userId) === userId)) {

      let oldData = (data.find(o => decryptId(o.userId) !== userId)) ? data.filter(o => decryptId(o.userId) !== userId) : null ;
      dataArray = (oldData === null) ? [dataObj] : oldData.concat(dataObj);

    } else {
      let oldData = (data.find(o => decryptId(o.userId) !== userId)) ? data.filter(o => decryptId(o.userId) !== userId) : null ;
      dataArray = (oldData === null) ? [dataObj] : oldData.concat(dataObj);
    }

      window.localStorage.setItem(nameStorage, JSON.stringify(dataArray));
  })
}


/***
 * Input validation
 *
 ***/
for (const inputText of allInputsText) {

  checkValueStorage(dataAll, localDatasJson, inputText, 'text');
  storeValueStorage(dataAll, nameStorage, inputText, 'text');
}


/***
 * Textarea validation
 *
 ***/
for (const textArea of allTextarea) {

  checkValueStorage(dataAll, localDatasJson, textArea, 'text');
  storeValueStorage(dataAll, nameStorage, textArea, 'text');
}


/***
 * Checkbox validation
 *
 ***/
for (const checkBox of allInputsCheckbox) {

  checkValueStorage(dataAll, localDatasJson, checkBox, 'checkbox');
  storeValueStorage(dataAll, nameStorage, checkBox, 'checkbox');
}


/***
 * Select validation
 *
 ***/
for (const selectItem of allSelect) {

  checkValueStorage(dataAll, localDatasJson, selectItem, 'select');
  storeValueStorage(dataAll, nameStorage, selectItem, 'select');
}


